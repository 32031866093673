'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const CardGrid = styled.div<{ cols: number }>`
  display: grid;
  grid-template-columns: ${(props) => Array(props.cols).fill('1fr').join(' ')};
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: 20px;

  @media (max-width: ${breakPoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;
