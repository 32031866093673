import React from 'react';

import * as Styled from './styled';

interface CardlistProps {
  compressed?: boolean;
  children: React.ReactNode;
}

const Cardlist: React.FC<CardlistProps> = ({ children, compressed = false }) => {
  return <Styled.Cardlist $compressed={compressed}>{children}</Styled.Cardlist>;
};

export default Cardlist;
