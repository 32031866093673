'use client';

import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const RepositoryCard = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 10px; */
  justify-content: space-between;
  background: ${colors.primary.white};
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: 5px;
  padding: 20px;

  p {
    padding: 0px;
    margin: 0px;
    max-width: 85ch;
  }

  .timestamp {
    font-size: 12px;
    white-space: nowrap;
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 10px;
    /* border: 1px solid lime; */
    .repo-card-row {
      /* border: 1px solid magenta; */
      flex-direction: column;
      gap: 10px !important;
    }
  }
`;

export const ProgressGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  border: 1px solid ${colors.secondary.blue2};
  color: ${colors.secondary.blue2};
  border-radius: 5px;
  padding: 20px;
`;
