import styled from 'styled-components';

import { colors, breakPoints } from '@/styles';

export const Pagination = styled.div`
  ul {
    display: inline-flex;
    font-size: 14px;
    margin: 0;
    padding: 0;
    /* display: flex; */
    flex-direction: row;
    list-style-type: none;
    li a {
      display: inline-block;
      padding: 10px;
      line-height: 1;
      min-width: 2.5rem;
      text-align: center;
      border: var(--color-secondary-lightGray2) 1px solid;
      cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }
    li.active a {
      background-color: var(--color-secondary-blue2);
      border-color: transparent;
      color: white;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    ul {
      font-size: 12px;
      li a {
        padding: 8px;
      }
    }
  }
`;

export const CompactPagination = styled.div`
  ul {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    margin: 0;
    padding: 0;
    /* display: flex; */
    flex-direction: row;
    list-style-type: none;
    li {
      /* border: 1px solid orange; */
      /* padding: 3px; */
      padding: 2px;
    }
    li a {
      display: inline-block;
      padding: 1px;
      line-height: 1;

      min-width: 1.25rem;
      text-align: center;
      cursor: pointer;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
    }
    li.active a {
      border-radius: 3px;
      background-color: ${colors.secondary.lightGray2};
      border: 1px solid ${colors.secondary.lightGray3};
      font-weight: 500;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  }
  svg {
    /* width: 1rem; */
    height: 12px;
  }
  @media (max-width: ${breakPoints.mobile}) {
    ul {
      font-size: 12px;
      li a {
        padding: 8px;
      }
    }
  }
`;
