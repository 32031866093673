import { IRepositoryCategory } from '@/types';

export const flattenCategoriesUp = (
  categories: IRepositoryCategory[],
  accumulator: IRepositoryCategory[] = [],
): IRepositoryCategory[] => {
  let result = accumulator;
  categories.forEach((category) => {
    if (result.find((c) => c.id === category.id)) {
      return;
    }
    result.push(category);
    if (category.parent) {
      result = flattenCategoriesUp([category.parent], result);
    }
  });
  return result.sort((a, b) => a.slug.localeCompare(b.slug));
};

export const flattenCategoriesDown = (
  categories: IRepositoryCategory[],
  accumulator: IRepositoryCategory[] = [],
): IRepositoryCategory[] => {
  let result = accumulator;
  categories.forEach((category) => {
    result.push(category);
    if (category.children) {
      result = flattenCategoriesDown(category.children, result);
    }
  });
  return result;
};
