'use client';
import styled, { css } from 'styled-components';

const compreseedChildCss = css`
  border-radius: 0px !important;
  border-top-width: 0px !important;
  &:first-child {
    border-top-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }
  &:last-child {
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
`;

export const Cardlist = styled.div<{ $compressed?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$compressed ? 0 : 25)}px;

  & > * {
    ${(props) => (props.$compressed ? compreseedChildCss : null)};
  }
`;
