'use client';
import React from 'react';
import * as Icon from '@/ui/Icon';
import ReactPaginate from 'react-paginate';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import isMobile from 'ismobilejs';

import * as Styled from './styled';

import { IPagination } from '@/types';

const Pagination: React.FC<{
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  onClick?: (pageNum: number) => void;
  pagination: IPagination;
  compact?: boolean;
}> = ({ pageRangeDisplayed = 10, marginPagesDisplayed = 5, pagination, onClick, compact }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();

  const mobileView = isMobile().phone;

  const getLinkUrl = (page: number) => {
    const search = new URLSearchParams(searchParams);
    search.set('page', page.toString());
    return `${pathname}?${search.toString()}`;
  };

  const onPageChange = (event: { selected: number }) => {
    const { selected: selectedIndex } = event;
    const pageNum = selectedIndex + 1;
    if (onClick) {
      onClick(pageNum);
    } else {
      router.push(getLinkUrl(pageNum));
      // router.push(`${pathname}?page=${pageNum}`, undefined);
      // const win: Window = window;
      // win.location = `${pathname}?page=${pageNum}`;
    }
  };
  // console.log('searchParams', Object.fromEntries(searchParams));

  const Paginator = () => (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<Icon.Right />}
      onPageChange={onPageChange}
      pageRangeDisplayed={mobileView ? 2 : pageRangeDisplayed}
      marginPagesDisplayed={mobileView ? 1 : marginPagesDisplayed}
      hrefBuilder={(page) => getLinkUrl(page)}
      pageCount={parseInt(pagination?.total_pages.toString()) || 1}
      forcePage={Math.ceil(pagination?.page_number || 1) - 1}
      previousLabel={<Icon.Left />}
      activeClassName="active"
    />
  );

  return compact ? (
    <Styled.CompactPagination>
      <Paginator />
    </Styled.CompactPagination>
  ) : (
    <Styled.Pagination>
      {/* <div>{pathname}</div> */}
      <Paginator />
    </Styled.Pagination>
  );
};

export default Pagination;
