import { IRepository } from '@/types';
import { apiClient, fetchWrapper, getRequestHeaders, IBaseResponse } from './apiBase';
import { BASE_API_URL } from '@/config/environment';

export interface IRepositoryStarResponse extends IBaseResponse {
  star_count: number;
  starred: boolean;
}

export const fetchStars = async (
  namespace: string,
  repositoryName: string,
  token?: string,
): Promise<IRepositoryStarResponse> => {
  const targetUrl = `${BASE_API_URL}/repos/${namespace}/${repositoryName}/stars`;

  return await fetchWrapper({
    resourceName: 'stars',
    targetUrl,
    authToken: token,
    resourceContext: { namespace, repositoryName },
  });
};

export const star = async (repository: IRepository, token?: string | undefined): Promise<IRepositoryStarResponse> => {
  const resourceUrl = `/repos/${repository.namespace}/${repository.name}/stars`;
  const requestHeaders = getRequestHeaders(token);
  try {
    const res = await apiClient.post(resourceUrl, {}, { headers: requestHeaders });
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const unstar = async (repository: IRepository, token?: string | undefined): Promise<IRepositoryStarResponse> => {
  const resourceUrl = `/repos/${repository.namespace}/${repository.name}/stars`;
  const requestHeaders = getRequestHeaders(token);
  try {
    const res = await apiClient.delete(resourceUrl, { headers: requestHeaders });
    return res.data;
  } catch (error: any) {
    return error.response.data;
  }
};
