import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { FOCUS_THROTTLE_INTERVAL } from '@/config/appSettings';

import * as API from '@/lib/repositoryStarsApi';
import { IResponseError } from '@/lib/apiBase';
import { IRepository, IUser } from '@/types';

const useRepositoryStars = ({
  repository,
  currentUser,
  initialData,
}: {
  repository: IRepository;
  currentUser?: IUser;
  initialData?: API.IRepositoryStarResponse;
}) => {
  const [starCount, setStarCount] = useState<number>(repository.star_count || initialData?.star_count || 0);
  const [isStarred, setIsStarred] = useState<boolean>(!!repository.starred_at || initialData?.starred || false);
  const [status, setStatus] = useState<'success' | 'error' | 'pending'>('pending');
  const [error, setError] = useState<IResponseError | undefined>();
  const fetchKey = repository ? ['repository_stars', repository.id] : null;

  const {
    data,
    error: requestError,
    isLoading,
    isValidating,
  } = useSWR(fetchKey, () => API.fetchStars(repository.namespace, repository.name), {
    fallbackData: initialData,
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    focusThrottleInterval: FOCUS_THROTTLE_INTERVAL,
  });

  useEffect(() => {
    if (data) {
      setStarCount(data.star_count || 0);
      setIsStarred(data.starred);
      setStatus(data.status);
      setError(data.error as IResponseError);
    }
  }, [currentUser, data]);

  const onSuccess = (response: API.IRepositoryStarResponse): void => {
    setError(undefined);
    const { star_count, starred } = response;
    setStarCount(star_count);
    setIsStarred(starred);
  };

  const onFail = (response: API.IRepositoryStarResponse): void => {
    setError(response.error as IResponseError);
  };

  const star = async (): Promise<API.IRepositoryStarResponse> => {
    setStatus('pending');
    setError(undefined);
    const response = await API.star(repository);
    const { status } = response;
    setStatus(status);

    if (status === 'success') {
      onSuccess(response);
    } else {
      onFail(response);
    }
    return response;
  };

  const unStar = async (): Promise<string> => {
    setStatus('pending');
    setError(undefined);
    const response = await API.unstar(repository);
    const { status } = response;
    setStatus(status);

    if (status === 'success') {
      onSuccess(response);
    } else {
      onFail(response);
    }
    return response?.status;
  };

  return { starred: isStarred, starCount, star, unStar, status, error };
};

export default useRepositoryStars;
