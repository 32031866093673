'use client';
import React from 'react';
import CardList from '@/ui/CardList';
import ReposityCard from '@/components/Repository/RepositoryCard';
import Pagination from '@/components/Pagination';
import * as Icon from '@/ui/Icon';
import { usePathname, useRouter } from 'next/navigation';

import { IPagination, IRepository } from '@/types';
import { IRepositoriesResponse } from '@/lib/repositoriesApi';
import { Stack, Row, Card } from '@/ui/primatives';
import CardGrid from '@/ui/CardGrid';
import OxenInstallationInstructions from '@/components/OxenInstallationInstructions';

const GridView: React.FC<{ repositories: IRepository[]; namespace?: string; hidePublicPrivateIndicator?: boolean }> = ({
  repositories,
  namespace,
  hidePublicPrivateIndicator,
}) => (
  <CardGrid>
    {repositories?.map((repo: IRepository) => (
      <ReposityCard
        key={repo.id}
        repository={repo}
        showNamespace={!namespace || namespace !== repo.namespace}
        hidePublicPrivateIndicator={hidePublicPrivateIndicator}
      />
    ))}
  </CardGrid>
);

const ListView: React.FC<{ repositories: IRepository[]; namespace?: string; hidePublicPrivateIndicator?: boolean }> = ({
  repositories,
  namespace,
  hidePublicPrivateIndicator,
}) => (
  <CardList compressed>
    {repositories?.map((repo: IRepository) => (
      <ReposityCard
        key={repo.id}
        repository={repo}
        showNamespace={!!(namespace && namespace !== repo.namespace)}
        hidePublicPrivateIndicator={hidePublicPrivateIndicator}
      />
    ))}
  </CardList>
);

const ListHeader: React.FC<{
  title?: string;
  titleActionComponent?: React.ReactNode;
  pagination: IPagination;
  paginationEnabled?: boolean;
}> = ({ title, titleActionComponent = null, pagination, paginationEnabled = true }) => {
  return title || titleActionComponent || paginationEnabled ? (
    <Stack gap={20}>
      {!!title && (
        <h5>
          <Row gap={10} alignItems="center">
            <Icon.Book />
            {title}
          </Row>
          {titleActionComponent}
        </h5>
      )}
      {paginationEnabled && pagination?.total_entries > 0 && (
        <div>
          Displaying Page {pagination?.page_number} of {pagination?.total_pages} ({pagination?.total_entries} total
          Repositories)
        </div>
      )}
    </Stack>
  ) : null;
};

const RepositoryList: React.FC<{
  title?: string;
  namespace?: string;
  responseData: IRepositoriesResponse | any; // TODO: Fix this any
  titleActionComponent?: React.ReactNode;
  layout?: 'grid' | 'list';
  hidePublicPrivateIndicator?: boolean;
  disablePagination?: boolean;
  // onPageChange?: (pageNum: number) => void;
}> = ({
  title,
  namespace,
  responseData,
  titleActionComponent = null,
  layout = 'list',
  hidePublicPrivateIndicator,
  disablePagination = false,
}) => {
  const { repositories, pagination, isLoading } = responseData;
  const paginationEnabled = !disablePagination;
  const showPagination = paginationEnabled && pagination?.total_pages > 1;

  const router = useRouter();
  const pathname = usePathname();

  const onPageChange = (pageNum: number) => {
    router.push(`${pathname}?page=${pageNum}`);
  };

  return (
    <Stack gap={40}>
      <ListHeader
        title={title}
        titleActionComponent={titleActionComponent}
        pagination={pagination}
        paginationEnabled={paginationEnabled}
      />
      {isLoading && <div>Loading...</div>}

      {repositories?.length == 0 ? (
        <>
          <Card>
            <OxenInstallationInstructions />
          </Card>
        </>
      ) : (
        <></>
      )}

      {layout === 'grid' ? (
        <GridView
          repositories={repositories}
          namespace={namespace}
          hidePublicPrivateIndicator={hidePublicPrivateIndicator}
        />
      ) : (
        <ListView
          repositories={repositories}
          namespace={namespace}
          hidePublicPrivateIndicator={hidePublicPrivateIndicator}
        />
      )}

      {showPagination && <Pagination onClick={onPageChange} pagination={pagination} />}
    </Stack>
  );
};

export default RepositoryList;
