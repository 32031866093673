import React from 'react';
import * as Styled from './styled';

const CardGrid: React.FC<React.HTMLAttributes<HTMLDivElement> & { cols?: number; children: React.ReactNode }> = ({
  cols = 2,
  children,
  ...rest
}) => {
  return (
    <div>
      <Styled.CardGrid cols={cols} {...rest}>
        {children}
      </Styled.CardGrid>
    </div>
  );
};

export default CardGrid;
