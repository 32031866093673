import styled from 'styled-components';
import { breakPoints } from '@/styles';

export const RepositoryStarControl = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

export const SimpleStar = styled.span`
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;
